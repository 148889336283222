<template>
  <div>
    <v-row> {{ label }} </v-row>
    <v-row>
      <PrepareFilesUpload
        ref="refPrepareFilesUpload"
        :isShowLocale="true"
        :propsLocaleValue="uploadFilesModel.locale"
        :propsUploadedFiles="uploadFiles"
        @checkTargetFiles="$emit('checkTargetFiles', $event)"
        @localeChangeType="localeChangeType"
      />
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PrepareFilesUpload from "@/components/commonV2/ui/PrepareFilesUpload.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: {
    PrepareFilesUpload,
  },
  props: {
    label: {
      type: String,
      default: "Before & After"
    },
  },
  data() {
    return {
      uploadFiles: [],
      uploadFilesModel: modelService.uploadFilesModel(),
      hospital_id: 0,
      product_id: 0
    };
  },
  methods: {
    async _getUploadFiles(hospital_id, product_id, locale) {
      this.product_id = product_id;
      this.hospital_id = hospital_id;
      this.uploadFilesModel.endpoint = "uploadFiles";
      this.uploadFilesModel.hospital_id = hospital_id;
      this.uploadFilesModel.user_id = 0;
      this.uploadFilesModel.product_id = product_id;
      this.uploadFilesModel.fileGbn = "beforeAfter";
      this.uploadFilesModel.locale = locale; //this.uploadFilesModel.locale === ''? 'ko':this.uploadFilesModel.locale;
      await this.getDataQuery(this.uploadFilesModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        // console.table(res);
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate,true),
          }));
        }
        this.uploadFiles = res;
      });
    },
    localeChangeType(e) {
      this.uploadFilesModel.locale = e;
      this.$emit("localeChangeType", e)
      this._getUploadFiles(this.hospital_id, this.product_id, e);
      // console.log("this.localeValue : ", e);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="">
</style>